export const parseBoolean = (params: PageContent.TBoolean): boolean =>
  params && typeof params === 'string' ? params === '1' : false;

export const formatLang = (lang) => {
  const [code, region] = lang.split('-');

  return `${code}-${region.toUpperCase()}`;
};

export const getFileNameWithoutExtension = (filename: string): string => {
  const parts = filename.split('.');
  if (parts.length > 1) {
    parts.pop();

    return parts.join('.');
  }

  return filename;
};

export const getStringWithoutTags = (str?: string): string => {
  if (typeof str !== 'string') return '';
  const htmlTagsRegex = /<[^>]+>/g;
  const containsTags = htmlTagsRegex.test(str);

  return containsTags ? str?.replace(htmlTagsRegex, '') : str;
};

export const capitalize = (str: string): string => {
  if (typeof str !== 'string') return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const replaceByRegExp = (regExp: RegExp, str: string, placeHolder: string): string => {
  if (regExp.test(str)) {
    return str.replace(regExp, placeHolder);
  }

  return str;
};

export default {
  parseBoolean,
};

const { loadableReady } = require('@loadable/component');

const { gtmService } = require('./src/services/gtmService');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      // eslint-disable-next-line no-undef
      ReactDOM.hydrate(element, container, callback);
    });
  };
};

exports.wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pageContext || {};
  gtmService.emitPageView(pageTemplate);
};
